import { getRecordsSortedByStatus, makeCommonItem } from '@/adapters/assessmentPlans/commonItem.adapter';
import { makeFileItem } from '@/adapters/files/files.adapter';
import { taskIndexAdapter } from '@/adapters/assessmentPlans/tasks/tasks.adapter';
import { roundNumber } from '@/services/Mathe.js';

export const respExpPlanSingleInputAdapter = (item, isMock) => {
  return {
    ...makePlanItem(item),

    files: item.files ? item.files.map(file => makeFileItem(file)) : [],
    tests: item.tests ? getRecordsSortedByStatus(taskIndexAdapter(item.tests)) : [],
  };
}

export const respondentsAdapter = (respondents) => {
  return respondents ?
    respondents.map((respondent) => {
      return {
        uuid: respondent.uuid,
        fullName: respondent.full_name,
        age: respondent.year,
        reports: respondent.reports,
        position: respondent.position,
        grade: respondent.grade,
        totalScore: roundNumber(respondent.balls),
        tests: respondent.tests.map((task) => {
          let modifiedTask = {
            title: task.name,
            type: task.type,
            uuid: task.uuid,
            isOpen: false,
            isSet: task.completed,
            isFailed: task.failed,
            hasNotifications: task.notifications,
            reports: task.reports ?? [],
            sessionUuid: task.session_uuid,
            criterias: task.scales ? task.scales.map((scale) => {
              return {
                uuid: scale.uuid,
                name: scale.name,
                currentScore: roundNumber(scale.current),
                maxScore: scale.max
              }
            }) : [],
          };
          switch (task.type) {

            case 'confirmation':
              modifiedTask.isChecked = task.confirm;
              modifiedTask.icon = 'twoticks';
              break;
            case 'test':
              modifiedTask.totalScore = task.total;
              modifiedTask.currentScore = roundNumber(task.current);
              modifiedTask.icon = 'paper';

              break;
            case 'expert':
            case 'inspector':
              modifiedTask = Object.assign(modifiedTask, {
                currentScore: roundNumber(task.current),
                totalScore: task.total,
                totalMaxScore: roundNumber(task.totalMaxScore),
                isFailed: task.isFailed,
                isLocked: task.isLocked,
                icon: 'testexpert',
                hasNewMessages: task.notifications,
                sessionUuid: task.session_uuid,
              });
              break;
          }
          return modifiedTask;
        }).filter(task => !!task.title)
      }
    }) : [];
}

export const respondentPlansAdapter = respondents => {
  return respondents ?
      respondents.map((respondent) => {
        return {
          name: respondent.name,
          uuid: respondent.uuid,
          grade: respondent.grade,
          totalScore: roundNumber(respondent.balls),
          tests: respondent.tests.map((task) => {
            let modifiedTask = {
              title: task.name,
              type: task.type,
              uuid: task.uuid,
              isOpen: false,
              isSet: task.completed,
              isFailed: task.failed,
              hasNotifications: task.notifications,
              reports: task.reports ?? [],
              sessionUuid: task.session_uuid,
              criterias: task.scales ? task.scales.map((scale) => {
                return {
                  uuid: scale.uuid,
                  name: scale.name,
                  currentScore: roundNumber(scale.current),
                  maxScore: scale.max
                }
              }) : [],
            };
            switch (task.type) {

              case 'confirmation':
                modifiedTask.isChecked = task.confirm;
                modifiedTask.icon = 'twoticks';
                break;
              case 'test':
                modifiedTask.totalScore = task.total;
                modifiedTask.currentScore = roundNumber(task.current);
                modifiedTask.icon = 'paper';

                break;
              case 'expert':
              case 'inspector':
                modifiedTask = Object.assign(modifiedTask, {
                  currentScore: roundNumber(task.current),
                  totalScore: task.total,
                  totalMaxScore: roundNumber(task.totalMaxScore),
                  isFailed: task.isFailed,
                  isLocked: task.isLocked,
                  icon: 'testexpert',
                  hasNewMessages: task.notifications,
                  sessionUuid: task.session_uuid,
                });
                break;
            }
            return modifiedTask;
          }).filter(task => !!task.title)
        }
      }) : [];
}

export const respExpPlanIndexInputAdapter = (items) => {
  return makePlanItems(items)
};

export const respExpItemsArchiveInputAdapter = (items) => {
  return items.map(item => makePlanItem(item));
};

const makePlanItems = (items) => {
  return items.map(item => makePlanItem(item));
}
const makePlanItem = (item) => {
  let modifiedItem = makeCommonItem(item)
  return {
    ...modifiedItem,
    isCompleted: item.completed,
    progress: item.progress,
    participantsAmount: item.participants,
    score: roundNumber(item.balls?.current),
    totalScore: roundNumber(item.balls?.total),
    notifications: item.notifications,
    show_progress: item.show_progress
  }
};