import api from "@/network";

type DeleteDto = {
    planUuid: string;
    testUuid: string;
}

type CreateDto = {
    planUuid: string;
    name: string;
    description: string;
    files: any[];
    expert_files: any[];
}
export default {
    async delete(dto: DeleteDto) {
        return await api.delete(`plans/${dto.planUuid}/tests/${dto.testUuid}`);
    },
    async create(planUuid: string, dto: CreateDto) {
        dto.files = dto.files.map((file: any) => file.uuid);
        dto.expert_files = dto.expert_files.map((file: any) => file.uuid);
        return await api.post(`plans/${planUuid}/tests`, dto);
    },
    async update(planUuid: string, taskUuid: string, dto: any) {
        dto.files = dto.files.map((file: any) => file.uuid);
        dto.expert_files = dto.expert_files.map((file: any) => file.uuid);
        return await api.put(`plans/${planUuid}/tests/${taskUuid}`, dto);
    },
    async updateOrder(planUuid: string, taskUuid: string, order: number) {
        return await api.put(`plans/${planUuid}/tests/${taskUuid}/order`, {
            order: order,
        });
    },
    async getTestScales (planUuid: string, uuid: string) {
        return api.get(`plans/${planUuid}/tests/${uuid}/scales`);
    },

    async get (planUuid: string, uuid: string) {
        return api.get(`plans/${planUuid}/tests/${uuid}`);
    }

}